import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Column } from "../../utility/interface/common";

const initialState: Column = {
  _id: "",
  image_url: "",
  topic_id: 0,
  topic_content: "",
  publishedAt: "",
  fetcheAt: "",
  eng_content: "",
  zh_tw_content: "",
  zh_cn_content: "",
  es_content: "",
  ko_content: "",
  ja_content: "",
  eng_title: "",
  zh_tw_title: "",
  zh_cn_title: "",
  es_title: "",
  ko_title: "",
  ja_title: "",
  title: "",
  content: "",
};

export const selectionSlice = createSlice({
  name: "selection",
  initialState,
  reducers: {
    chooseColumn: (state, action: PayloadAction<Column>) => {
      return action.payload;
    },
    removeSelectionInfo: (state) => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { chooseColumn, removeSelectionInfo } = selectionSlice.actions;

export default selectionSlice.reducer;
