import { useTheme } from "../../context/ThemeContext";
import { Article } from "../../utility/interface/common";
import "./style.scss";

const ArticleCard = (props: Article) => {
  const { _id, source, title, summary, url, publishedAt, refer } = props;
  const { isDarkMode } = useTheme();

  return (
    <div
      ref={refer}
      className={isDarkMode ? "article-card dark-card" : "article-card"}
    >
      <div className="article-details">
        <a
          className={isDarkMode ? "article-title title-dark" : "article-title"}
          href={url}
        >
          <h2>{title}</h2>
        </a>
        <span className="article-date">
          {source} {publishedAt}
        </span>
        <p
          className={
            isDarkMode ? "article-summary summary-dark" : "article-summary"
          }
        >
          {summary}
        </p>
      </div>
    </div>
  );
};

export default ArticleCard;
