import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Skeleton, Space, Spin } from "antd";

import { useTheme } from "../context/ThemeContext";
import ColumnList from "../components/ColumnList";
import ArticleList from "../components/ArticleList";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { updateLoading } from "../features/settings/settingsSlice";
import { getColumn, getList } from "../server/list";
import { LoadingOutlined } from "@ant-design/icons";

// import { columnData } from "../../column_fakeData.js";
// import { data } from "../../fake-data.js";

const Item = (props: any) => {
  const { t } = useTranslation();
  const settingsState = useAppSelector((state: any) => state.settings);
  const [list, setList] = useState<any>([]);
  const [listIndex, setListIndex] = useState<number>(1);
  const [columnList, setColumnList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { isDarkMode, toggleTheme } = useTheme();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setIsLoading(true);
    setList([]);
    setColumnList([]);

    getList(settingsState.region, settingsState.category, listIndex)
      .then((res) => {
        const data = res.data.news;
        setList(data);
      })
      .catch((err) => console.log(err));

    getColumn(settingsState.region)
      .then((res) => {
        const data = res.data.column[0];
        setColumnList(data);
        setIsLoading(false);
        dispatch(updateLoading(false));
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        dispatch(updateLoading(false));
      });

    // fake data
    // setList(data);
    // setColumnList(columnData.column[0]);
    // setIsLoading(false);
    // dispatch(updateLoading(false));
  }, [settingsState.region, settingsState.category]);

  return (
    <>
      <div className="wrapper">
        <main>
          {isLoading && columnList.length === 0 && (
            <div className="loading-wrapper">
              <Spin
                indicator={
                  <LoadingOutlined
                    spin
                    style={{
                      fontSize: 72,
                      color: "#000",
                      margin: ".5em 0",
                    }}
                  />
                }
              />
              <Space />
              <div>{t("Fetching columns...")}</div>
            </div>
          )}
          <ColumnList source={columnList} />
        </main>
      </div>

      <div className={isDarkMode ? "article-wrapper dark" : "article-wrapper"}>
        <div className="article-list">
          <div className={isDarkMode ? "section-title dark" : "section-title"}>
            <h2>{t("Recent News")}</h2>
          </div>
          {list.length == 0 || settingsState.loading ? (
            <Skeleton active />
          ) : (
            <>
              <ArticleList source={list} isLoading={isLoading} />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Item;
