import "./style.scss";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Item } from "../../utility/interface/common";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useTheme } from "../../context/ThemeContext";
import SignUpModal from "../SignUpModal";
import moment from "moment";

import {
  updateKeyword,
  updateLoading,
  updateRegion,
} from "../../features/settings/settingsSlice";

import {
  FacebookFilled,
  LinkedinFilled,
  MailOutlined,
  XOutlined,
} from "@ant-design/icons";
import { searchedNews } from "../../features/searched/searchedSlice";

const region: Item[] = [
  {
    id: "us",
    title: "US",
  },
  { id: "tw", title: "TW" },
];

const TopHeader: React.FC = () => {
  const { t } = useTranslation();
  const settingsState = useAppSelector((state: any) => state.settings);
  const [selectedRegion, setSelectedRegion] = useState("us");
  const [signUpModal, setSignUpModalOpen] = useState(false);

  const { isDarkMode } = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setSelectedRegion(settingsState.region);
  }, []);

  return (
    <>
      <SignUpModal
        modalState={signUpModal}
        onCancelFn={() => setSignUpModalOpen(false)}
      />
      <div
        className={isDarkMode ? "top-select-list top-dark" : "top-select-list"}
      >
        <div className="top-list-items">
          <ul className={isDarkMode ? "top dark" : "top"}>
            <li key="date">{moment().format("DD MMMM, YYYY")}</li>
            {region.map((item) => {
              return (
                <li
                  key={item.id}
                  className={item.id == selectedRegion ? "selected" : ""}
                >
                  <span
                    onClick={() => {
                      dispatch(updateRegion(item.id));
                      dispatch(updateLoading(true));
                      dispatch(updateKeyword(""));
                      dispatch(searchedNews([]));
                      setSelectedRegion(item.id);
                      navigate("/");
                    }}
                  >
                    {item.title}
                  </span>
                </li>
              );
            })}
          </ul>
          <nav className={isDarkMode ? "social-share dark" : "social-share"}>
            <li
              className="sign-up-text"
              onClick={() => setSignUpModalOpen(true)}
            >
              {t("Sign Up")}
            </li>
            <li data-href="https://axonnew.com/">
              <a
                target="_blank"
                href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Faxonnew.com%2F&amp;src=sdkpreparse"
              >
                <FacebookFilled />
              </a>
            </li>

            <li>
              <a
                target="_blank"
                href="https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Faxonnew.com"
              >
                <LinkedinFilled />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://twitter.com/intent/tweet?url=https%3A%2F%2Faxonnew.com"
              >
                <XOutlined />
              </a>
            </li>
            <li>
              <a href="mailto:info@neurowatt.ai?subject=AxonNews">
                <MailOutlined />
              </a>
            </li>
          </nav>
        </div>
      </div>
    </>
  );
};

export default TopHeader;
