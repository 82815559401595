import moment from "moment";
import { Item } from "../interface/common";

export const categories: Item[] = [
  {
    id: "geopolitics",
    title: "geopolitics",
  },
  {
    id: "technology",
    title: "technology",
  },
  {
    id: "economics",
    title: "economics",
  },
  {
    id: "biomedicine",
    title: "biomedicine",
  },
  {
    id: "industry",
    title: "industry",
  },
];

export const langs: Item[] = [
  {
    id: "en_",
    title: "English",
  },
  { id: "zh_tw_", title: "繁體中文" },
  { id: "zh_cn_", title: "简体中文" },
  { id: "ja_", title: "日本語" },
  { id: "es_", title: "Español" },
  { id: "ko_", title: "한국어" },
];

export const processDate = (publishedTime: string) => {
  if (!window.navigator.userAgent.includes("Chrome")) {
    let date = publishedTime.split("UTC");
    return date[0];
  }
  return moment(publishedTime).format("MMMM DD, YYYY HH:MM");
};
