import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  news: [],
  loading: false,
};

export const searchedSlice = createSlice({
  name: "searched",
  initialState,
  reducers: {
    searchedNews: (state, action: PayloadAction<any>) => {
      return { ...state, news: action.payload };
    },
    searchNewsLoading: (state, action: PayloadAction<boolean>) => {
      return { ...state, loading: action.payload };
    },
    removesearched: (state) => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { searchedNews, searchNewsLoading, removesearched } =
  searchedSlice.actions;

export default searchedSlice.reducer;
