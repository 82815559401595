import axiosClient from "./axios";
import { signupBlob } from "../utility/interface/common";

export const getList = (region: string, category: string, page: number) => {
  const url = `/get-latest-news/${region}/${category}/${page}`;
  const config = {
    headers: {
      "ngrok-skip-browser-warning": "69420",
    },
  };
  return axiosClient.get<any>(url, config);
};

export const searchNews = (region: string, category: string, query: string) => {
  const url = `/search-news/${region}/${category}/${query}`;
  const config = {
    headers: {
      "ngrok-skip-browser-warning": "69420",
    },
  };
  return axiosClient.get<any>(url, config);
};

export const getColumn = (region: string) => {
  const url = `/get-column/${region}`;
  const config = {
    headers: {
      "ngrok-skip-browser-warning": "69420",
    },
  };
  return axiosClient.get<any>(url, config);
};

export const getMoreColumn = (region: string) => {
  const url = `/get-30-column/${region}`;
  const config = {
    headers: {
      "ngrok-skip-browser-warning": "69420",
    },
  };
  return axiosClient.get<any>(url, config);
};

export const getMultiLingualKeywords = (region: string) => {
  const url = `/get-multilingual-keywords/${region}`;
  const config = {
    headers: {
      "ngrok-skip-browser-warning": "69420",
    },
  };
  return axiosClient.get<any>(url, config);
};

export const signUpUser = (signupBlob: signupBlob) => {
  const url = `/register`;
  const body = signupBlob;
  return axiosClient.post<any>(url, body);
};
