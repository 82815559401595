import "./style.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../context/ThemeContext";
import { useAppDispatch } from "../../store/hooks";
import { chooseColumn } from "../../features/selection/selectionSlice";
import { processDate } from "../../utility/common/common";

const ColumnCard = (props: any) => {
  const { t } = useTranslation();
  const { key, _id, title, content, image_url, publishedAt, column } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const chooseColumnFn = () => {
    dispatch(chooseColumn(column));
    navigate(`/breaking-news/${title}`);
  };

  return (
    <div key={key} className="side-post" onClick={() => chooseColumnFn()}>
      <div className="square">
        <img src={`${image_url}`} />
        <div className="square-text">
          <h3 className="column-title">{title}</h3>
          <span className="published-at">{processDate(publishedAt)}</span>
        </div>
      </div>
    </div>
  );
};

export default ColumnCard;
