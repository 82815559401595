import "./style.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import { Skeleton } from "antd";
import { useTranslation } from "react-i18next";
import ArticleCard from "../ArticleCard";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useTheme } from "../../context/ThemeContext";
import { Article } from "../../utility/interface/common";
import { getList, searchNews } from "../../server/list";
import { updateKeyword } from "../../features/settings/settingsSlice";
import { removesearched } from "../../features/searched/searchedSlice";

const ArticleList = (props: any) => {
  const { t } = useTranslation();
  const { isDarkMode } = useTheme();
  const settingsState = useAppSelector((state: any) => state.settings);
  const searchedState = useAppSelector((state: any) => state.searched);
  const [list, setList] = useState<any>([]);
  const [index, setIndex] = useState<number>(1);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const observer = useRef<any>();
  const dispatch = useAppDispatch();

  const getTitle = (item: Article) => {
    switch (settingsState.lang) {
      case "zh_tw_":
        return item.zh_tw_title;
      case "zh_cn_":
        return item.zh_cn_title;
      case "es_":
        return item.es_title;
      case "ko_":
        return item.ko_title;
      case "ja_":
        return item.ja_title;
      default:
        return item.eng_title;
    }
  };
  const getSummary = (item: Article) => {
    switch (settingsState.lang) {
      case "zh_tw_":
        return item.zh_tw_summary;
      case "zh_cn_":
        return item.zh_cn_summary;
      case "es_":
        return item.es_summary;
      case "ko_":
        return item.ko_summary;
      case "ja_":
        return item.ja_summary;
      default:
        return item.eng_summary;
    }
  };

  const filterData = () => {
    if (settingsState.keyword !== "" && searchedState.loading === false) {
      return searchedState.news;
    } else if (searchedState.news.length !== 0) {
      return searchedState.news;
    } else {
      return props.source;
    }
  };

  const filteredData = filterData();

  const loadMoreArticles = async () => {
    // this is the foundation for infinite scroll
    try {
      setLoading(true);
      const newArticles = await getList(
        settingsState.region,
        settingsState.category,
        index
      );
      const data = await newArticles.data.news;
      setList((prevPosts: any) => [...prevPosts, ...data]);
      setLoading(false);
    } catch (data) {
      console.log("Something went wrong");
      setLoading(false);
      setError(true);
    }
  };

  const lastPostElementRef = useCallback(
    // this detects the last item in the article and adds to the list
    (node) => {
      if (loading || error || settingsState.keyword !== "") return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setIndex((prevIdx) => prevIdx + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading]
  );

  const returnRef = (index: number) => {
    // this is what we need to put in each article card item for determining the ref
    return list.length === index + 1 ? lastPostElementRef : null;
  };

  const clearSearch = () => {
    setIndex(1);
    dispatch(updateKeyword(""));
    dispatch(removesearched());
  };

  useEffect(() => {
    // load more article as the list change for infite scroll
    if (index > 1) {
      loadMoreArticles();
    }
  }, [index]);

  useEffect(() => {
    setList(filteredData);
  }, [
    props.source,
    settingsState.keyword,
    settingsState.category,
    searchedState.loading,
  ]);

  return (
    <>
      {settingsState.keyword !== "" && (
        <div className={isDarkMode ? "search-results dark" : "search-results"}>
          <h2>
            {t("Searched Results")}: <strong>{settingsState.keyword}</strong>
          </h2>
          &nbsp;
          <span onClick={() => clearSearch()}>{t("clear search")}</span>
        </div>
      )}
      {searchedState.loading === false &&
        list.map((article: any, index: number) => {
          return (
            <ArticleCard
              key={article._id}
              source={article.source}
              category={article.category}
              author={article.author}
              title={getTitle(article)}
              summary={getSummary(article)}
              url={article.url}
              publishedAt={article.publishedAt}
              fetcheAt={article.fetcheAt}
              refer={settingsState.keyword == "" ? returnRef(index) : null}
            />
          );
        })}

      {(loading || searchedState.loading) && <Skeleton active />}

      {list.length == 0 && (
        <div className={isDarkMode ? "no-results dark" : "no-results"}>
          {t("No results shown")}
        </div>
      )}
    </>
  );
};

export default ArticleList;
