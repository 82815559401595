import React, { CompositionEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Item, KeyWordData } from "../../utility/interface/common";
import "./style.scss";

import { useTheme } from "../../context/ThemeContext";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { updateCategory } from "../../features/settings/settingsSlice";
import {
  updateLang,
  updateKeyword,
} from "../../features/settings/settingsSlice";

import {
  CloseOutlined,
  DownOutlined,
  FacebookFilled,
  LinkedinFilled,
  MailOutlined,
  MoreOutlined,
  SearchOutlined,
  XOutlined,
} from "@ant-design/icons";
import {
  Button,
  Drawer,
  DrawerProps,
  Dropdown,
  MenuProps,
  Modal,
  Space,
} from "antd";

import { getMultiLingualKeywords, searchNews } from "../../server/list";
import { langs, categories } from "../../utility/common/common";
import {
  searchedNews,
  searchNewsLoading,
} from "../../features/searched/searchedSlice";

const items: MenuProps["items"] = [
  {
    key: "en_",
    label: "English",
  },
  {
    key: "zh_tw_",
    label: "繁體中文",
  },
  {
    key: "zh_cn_",
    label: "简体中文",
  },
  {
    key: "ja_",
    label: "日本語",
  },
  {
    key: "es_",
    label: "Español",
  },
  {
    key: "ko_",
    label: "한국어",
  },
];

const Header: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [searchingKeyword, setSearchingKeyword] = useState<any>(""); // 搜尋中 keyword for 非英文語系
  const [keyword, setKeyword] = useState<any>("");
  const [keywordList, setKeywordList] = useState<any>([]);
  const [selectedLang, setSelectedLang] = useState("en_");
  const [selectedCateogry, setSelectedCateogry] = useState("geopolitics");
  const [open, setOpen] = useState(false);

  const { isDarkMode } = useTheme();
  const settingsState = useAppSelector((state: any) => state.settings);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const selectLang = (lang: string) => {
    dispatch(updateLang(lang));
    setSelectedLang(lang);
  };

  const getCurrLang = () => {
    const found = langs.find((item) => item.id == selectedLang);
    return found?.title;
  };

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    selectLang(e.key);
    i18n.changeLanguage(e.key);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setKeyword(searchingKeyword);
    dispatch(updateKeyword(searchingKeyword));
    getSearchedNews(searchingKeyword);
    closeDrawer();
  };

  const getSearchedNews = async (query: string) => {
    dispatch(searchNewsLoading(true));
    try {
      const res = await searchNews(
        settingsState.region,
        settingsState.category,
        query
      );
      dispatch(searchedNews(res.data.news));
      dispatch(searchNewsLoading(false));
    } catch (err) {
      dispatch(searchedNews([]));
      dispatch(searchNewsLoading(false));
      console.log(err);
      if (err == 404) {
        console.log("Something went wrong");
      }
    }
  };
  // search drawer

  const showSearchDrawer = () => {
    setOpen(true);
  };

  const closeDrawer = () => {
    setSearchingKeyword("");
    setOpen(false);
  };

  const getKeyWordList = () => {
    getMultiLingualKeywords(settingsState.region).then((res) => {
      const list = res.data.multilingual_keywords[0].multilingual_data;
      const lang = getkeywordLang();
      const data = list[lang];
      setKeywordList(data);
    });
  };

  const handleKeywordTagClick = (keyword: string) => {
    dispatch(updateKeyword(keyword));
    setOpen(false);
    navigate("/");
  };

  const getkeywordLang = () => {
    switch (settingsState.lang) {
      case "zh_tw_":
        return "zh_tw";
      case "zh_cn_":
        return "zh_cn";
      case "es_":
        return "es";
      case "ko_":
        return "ko";
      case "ja_":
        return "ja";
      default:
        return "en";
    }
  };

  useEffect(() => {
    // getKeyWordList(); fake
  }, [settingsState.lang, settingsState.region]);

  useEffect(() => {
    if (settingsState.lang !== selectedLang) {
      setSelectedLang(settingsState.lang);
    }
    if (settingsState.category !== selectedCateogry) {
      setSelectedCateogry(settingsState.category);
    }
  }, []);

  return (
    <>
      <header className="header">
        <div className="nav-wrapper">
          <div className="nav-bar">
            <div className={isDarkMode ? "logo dark" : "logo"}>
              <h1 onClick={() => navigate("/")}>
                <span>
                  <img
                    className="logo-image"
                    src={
                      isDarkMode
                        ? `/images/logo-white.png`
                        : `/images/logo-dark.png`
                    }
                  />
                </span>
              </h1>
              <span className="beta">Beta</span>
            </div>
            <div className="main-nav-wrapper">
              <ul className={isDarkMode ? "nav-category dark" : "nav-category"}>
                {categories.map((item) => {
                  return (
                    <li
                      key={item.id}
                      className={item.id == selectedCateogry ? "selected" : ""}
                      onClick={() => {
                        setSelectedCateogry(item.id);
                        dispatch(updateKeyword(""));
                        dispatch(searchedNews([]));
                        dispatch(updateCategory(item.id));
                        navigate("/");
                      }}
                    >
                      <span>{t(item.id)}</span>
                    </li>
                  );
                })}
              </ul>
              <div className="search">
                <span
                  className={isDarkMode ? "lang-select dark" : "lang-select"}
                >
                  <Dropdown
                    menu={{ items, onClick: handleMenuClick }}
                    trigger={["click"]}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <Space>
                        {getCurrLang()}
                        <DownOutlined />
                      </Space>
                    </a>
                  </Dropdown>
                </span>

                <SearchOutlined
                  onClick={() => showSearchDrawer()}
                  className={isDarkMode ? "search-icon dark" : "search-icon"}
                />
              </div>
            </div>
          </div>
        </div>
      </header>
      <Drawer
        className={isDarkMode ? "search-drawer dark" : "search-drawer"}
        placement="right"
        width="100vw"
        closeIcon={false}
        onClose={() => {
          closeDrawer();
        }}
        open={open}
      >
        <div
          className={
            isDarkMode ? "close-offcanvasmeu dark" : "close-offcanvasmeu"
          }
        >
          <CloseOutlined className="close-icon" onClick={() => closeDrawer()} />
        </div>
        <div className="drawer-inner-item">
          <div className="search-bar">
            <form className="search-bar-form" onSubmit={handleSubmit}>
              <input
                className={
                  isDarkMode
                    ? "search-keyword-bar search-dark"
                    : "search-keyword-bar"
                }
                type="text"
                value={searchingKeyword}
                placeholder={t("Search...")}
                onChange={(e) => {
                  const keyword = e.target.value;
                  const treated = keyword.toLowerCase();
                  setSearchingKeyword(treated);
                }}
              />
              <button
                className={
                  isDarkMode ? "search-bar-icon dark" : "search-bar-icon"
                }
              >
                <SearchOutlined
                  className={
                    isDarkMode ? "search-bar-icon dark" : "search-bar-icon"
                  }
                />
              </button>
            </form>
          </div>

          <div className="keyword-list">
            {keywordList.map((item: KeyWordData) => {
              return (
                <span
                  key={item.keyword}
                  className="keyword-item"
                  onClick={() => handleKeywordTagClick(item.keyword)}
                >
                  {item.keyword}
                </span>
              );
            })}
          </div>
          <div
            className={isDarkMode ? "drawer-category dark" : "drawer-category"}
          >
            {categories.map((item) => {
              return (
                <span
                  key={item.id}
                  className={item.id == selectedCateogry ? "selected" : ""}
                  onClick={() => {
                    setSelectedCateogry(item.id);
                    dispatch(updateKeyword(""));
                    dispatch(searchedNews([]));
                    dispatch(updateCategory(item.id));
                    navigate("/");
                  }}
                >
                  {t(item.title)}
                </span>
              );
            })}
          </div>
          <div
            className={
              isDarkMode ? "drawer-social-share dark " : "drawer-social-share"
            }
          >
            <h3>{t("Follow Us")}</h3>
            <ul
              className={
                isDarkMode ? "drawer-social-share dark" : "drawer-social-share"
              }
            >
              <li data-href="https://axonnew.com/">
                <a
                  target="_blank"
                  href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Faxonnew.com%2F&amp;src=sdkpreparse"
                >
                  <FacebookFilled />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Faxonnew.com"
                >
                  <LinkedinFilled />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://twitter.com/intent/tweet?url=https%3A%2F%2Faxonnew.com"
                >
                  <XOutlined />
                </a>
              </li>
              <li>
                <a href="mailto:info@neurowatt.ai?subject=AxonNews">
                  <MailOutlined />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default Header;
