import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { SettingsInterface } from "../../utility/interface/slice";

const initialState: SettingsInterface = {
  keyword: "",
  lang: "en_",
  category: "geopolitics",
  region: "us",
  isLoading: false,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    updateKeyword: (state, action: PayloadAction<any>) => {
      return { ...state, keyword: action.payload };
    },
    updateLang: (state, action: PayloadAction<any>) => {
      return { ...state, lang: action.payload };
    },
    updateRegion: (state, action: PayloadAction<any>) => {
      return { ...state, region: action.payload };
    },
    updateCategory: (state, action: PayloadAction<any>) => {
      return { ...state, category: action.payload };
    },
    updateLoading: (state, action: PayloadAction<any>) => {
      return { ...state, loading: action.payload };
    },
    removeSettingsInfo: (state) => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateKeyword,
  updateLang,
  updateLoading,
  updateRegion,
  updateCategory,
  removeSettingsInfo,
} = settingsSlice.actions;

export default settingsSlice.reducer;
