import "./style.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../store/hooks";

import { Column } from "../../utility/interface/common";

import ColumnCard from "../ColumnCard";
import Trending from "../Trending";

// import { columnData } from "../../../column_fakeData.js";

const ColumnList = (props: any) => {
  const { t } = useTranslation();
  const [columnList, setColumnList] = useState<any>(undefined);
  const settingsState = useAppSelector((state: any) => state.settings);

  const getTitle = (item: Column) => {
    switch (settingsState.lang) {
      case "zh_tw_":
        return item.zh_tw_title;
      case "zh_cn_":
        return item.zh_cn_title;
      case "es_":
        return item.es_title;
      case "ko_":
        return item.ko_title;
      case "ja_":
        return item.ja_title;
      default:
        return item.eng_title;
    }
  };

  useEffect(() => {
    if (props.source !== undefined) {
      let data = props.source.column_data;
      setColumnList(data);
    }

    // fake data
    // setColumnList(columnData.column[0].column_data);
  }, [props]);

  return (
    <div key={0} className="column-list">
      {columnList !== undefined && (
        <>
          <Trending key={0} columnList={columnList} />
          <div className="column-wrapper">
            <div className="column-content">
              {columnList.map((item: Column) => {
                return (
                  <ColumnCard
                    key={item._id}
                    column={item}
                    image_url={item.image_url}
                    title={getTitle(item)}
                    publishedAt={item.publishedAt}
                  />
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ColumnList;
