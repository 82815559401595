import React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../context/ThemeContext";
import {
  FacebookFilled,
  LinkedinFilled,
  MailOutlined,
  XOutlined,
} from "@ant-design/icons";

import "./style.scss";

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();

  return (
    <>
      <footer
        className={isDarkMode ? "footer-list footer-dark" : "footer-list"}
      >
        <div className="footer-items">
          <div className={isDarkMode ? "logo dark" : "logo"}>
            <h1 onClick={() => navigate("/")}>
              <span>
                <img width={"200px"} src={`/images/logo-white.png`} />
              </span>
            </h1>
          </div>
          <nav
            className={
              isDarkMode ? "footer-social-share dark" : "footer-social-share"
            }
          >
            <li>
              <h2>{t("Follow Us")}</h2>
            </li>
            <li data-href="https://neuronews.neurowatt.ai/">
              <a
                target="_blank"
                href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fneuronews.neurowatt.ai%2F&amp;src=sdkpreparse"
              >
                <FacebookFilled />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fneuronews.neurowatt.ai"
              >
                <LinkedinFilled />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fneuronews.neurowatt.ai"
              >
                <XOutlined />
              </a>
            </li>
            <li>
              <a href="mailto:info@neurowatt.ai?subject=NeuroNews">
                <MailOutlined />
              </a>
            </li>
          </nav>
        </div>
        <div className="footer-items footer-copyrights">
          <ul>
            <li>{t("Terms of Use")}</li>
            <li>{t("Privacy Policy")}</li>
            <li>{t("Cookie Settings")}</li>
          </ul>
          <span>
            &copy; {moment().format("YYYY")} Axon News powered by{" "}
            <a target="_blank" href="https://neurowatt.ai">
              NeuroWatt.Ai
            </a>
            . All Rights Reserved.
          </span>
        </div>
      </footer>
    </>
  );
};

export default Footer;
