import "./App.scss";
import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "./app/store/hooks";

import Main from "./app/pages/Main";
import SignUp from "./app/pages/SignUp";

const App: React.FC = () => {
  const settingsState = useAppSelector((state: any) => state.settings);
  const { i18n } = useTranslation();

  const changeLanguageHandler = (lang: string) => {
    let currLang = lang ?? "en_";
    i18n.changeLanguage(currLang);
  };

  useEffect(() => {
    changeLanguageHandler(settingsState.lang);
  }, []);

  return (
    <>
      {" "}
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<Main />}></Route>
          {/* <Route path="/signup" element={<SignUp />}></Route> //TODO: for later */}
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
