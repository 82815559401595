import { useEffect, useState } from "react";
import { Skeleton, Space, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";

import ColumnCard from "../components/ColumnCard";

import { useAppDispatch, useAppSelector } from "../store/hooks";
import { updateLoading } from "../features/settings/settingsSlice";
import { getMoreColumn } from "../server/list";
import { Column } from "../utility/interface/common";

// import { columnData } from "../../column_fakeData.js";

const AllColumnPost = () => {
  const { t } = useTranslation();
  const settingsState = useAppSelector((state: any) => state.settings);
  const [columnList, setColumnList] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();

  const getTitle = (item: Column) => {
    switch (settingsState.lang) {
      case "zh_tw_":
        return item.zh_tw_title;
      case "zh_cn_":
        return item.zh_cn_title;
      case "es_":
        return item.es_title;
      case "ko_":
        return item.ko_title;
      case "ja_":
        return item.ja_title;
      default:
        return item.eng_title;
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setColumnList([]);

    getMoreColumn(settingsState.region)
      .then((res) => {
        const data = res.data;
        console.log(data.column_30);
        setColumnList(data.column_30);
        setIsLoading(false);
        dispatch(updateLoading(false));
      })
      .catch((err) => {
        setIsLoading(false);
        dispatch(updateLoading(false));
      });
  }, [settingsState.region, settingsState.category]);

  return (
    <div className="wrapper">
      {isLoading && (
        <div className="loading-wrapper">
          <Spin
            indicator={
              <LoadingOutlined
                spin
                style={{
                  fontSize: 72,
                  color: "#000",
                  margin: ".5em 0",
                }}
              />
            }
          />
          <Space />
          <div>{t("Fetching columns...")}</div>
        </div>
      )}
      <div className="column-list">
        <div className="column-content">
          {columnList &&
            columnList.map((item: Column) => {
              return (
                <ColumnCard
                  key={item._id}
                  column={item}
                  image_url={item.image_url}
                  title={getTitle(item)}
                  publishedAt={item.publishedAt}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default AllColumnPost;
