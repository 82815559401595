import "./style.scss";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useTheme } from "../../context/ThemeContext";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useNavigate } from "react-router-dom";

import { chooseColumn } from "../../features/selection/selectionSlice";

import { Column } from "../../utility/interface/common";

const Trending = (props: any) => {
  const { t } = useTranslation();
  const { isDarkMode } = useTheme();
  const [currentColumn, setCurrentColumn] = useState<number>(
    Math.floor(Math.random() * props.columnList.length)
  ); // this select the column at random
  const settingsState = useAppSelector((state: any) => state.settings);
  const { columnList } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const getTitle = (item: Column) => {
    switch (settingsState.lang) {
      case "zh_tw_":
        return item.zh_tw_title;
      case "zh_cn_":
        return item.zh_cn_title;
      case "es_":
        return item.es_title;
      case "ko_":
        return item.ko_title;
      case "ja_":
        return item.ja_title;
      default:
        return item.eng_title;
    }
  };

  const chooseColumnFn = (column: Column, title: string) => {
    dispatch(chooseColumn(column));
    navigate(`/breaking-news/${title}`);
  };

  const browseTrending = () => {
    if (currentColumn === columnList.length - 1) {
      setCurrentColumn(0);
    } else {
      setCurrentColumn(currentColumn + 1);
    }
  };

  const browseTrendingPrev = () => {
    if (currentColumn === 0) {
      setCurrentColumn(columnList.length - 1);
    } else {
      setCurrentColumn(currentColumn - 1);
    }
  };

  return (
    <div className="trending-wrapper">
      <div className="trend">
        <div className="tag">{t("Trending Now")}</div>
        <div
          className={isDarkMode ? "trend-title dark" : "trend-title"}
          onClick={() =>
            chooseColumnFn(
              columnList[currentColumn],
              columnList[currentColumn].title
            )
          }
        >
          {getTitle(columnList[currentColumn])}
        </div>
      </div>
      <div className="arrows">
        <button
          className={isDarkMode ? "trend-btn dark" : "trend-btn"}
          onClick={() => browseTrendingPrev()}
        >
          <ArrowLeftOutlined
            className={isDarkMode ? "arrows dark" : "arrows"}
          />
        </button>
        <button
          className={isDarkMode ? "trend-btn dark" : "trend-btn"}
          onClick={() => browseTrending()}
        >
          <ArrowRightOutlined
            className={isDarkMode ? "arrows dark" : "arrows"}
          />
        </button>
      </div>
    </div>
  );
};

export default Trending;
