import "./style.scss";
import { Modal, message } from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { useTheme } from "../../context/ThemeContext";
import { signUpUser } from "../../server/list";

interface SignUpModalInterface {
  modalState: boolean;
  onCancelFn: Function;
}

interface SignUpValues {
  username: string;
  email: string;
  password: string;
}

const SignUpModal = (props: SignUpModalInterface) => {
  const { t } = useTranslation();
  const { modalState, onCancelFn } = props;
  const { isDarkMode } = useTheme();
  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();

  const initialValues: SignUpValues = { username: "", email: "", password: "" };

  const SignupSchema = Yup.object().shape({
    username: Yup.string().required(t("Name Field is required")),
    email: Yup.string()
      .email(t("Incorrect Email format"))
      .required(t("Please provide your Email")),
    password: Yup.string()
      .min(6, t("Password must be longer than 6 characters"))
      .required(t("Password field is required")),
  });

  const signUpSuccess = () => {
    messageApi.open({
      type: "success",
      content: t("Sign Up Success!"),
    });
  };

  const signUpError = () => {
    messageApi.open({
      type: "error",
      content: t("Sign Up Failed!"),
    });
  };

  return (
    <>
      {contextHolder}
      <Modal
        centered
        open={modalState}
        onCancel={() => onCancelFn()}
        footer={null}
        className={isDarkMode ? "signup-modal dark" : "signup-modal"}
      >
        <div className={isDarkMode ? "signup-wrapper dark" : "signup-wrapper"}>
          <div className={isDarkMode ? "logo dark" : "logo"}>
            <h1 onClick={() => navigate("/")}>
              <span>
                <img
                  className="logo-image"
                  src={
                    isDarkMode
                      ? `/images/logo-white.png`
                      : `/images/logo-dark.png`
                  }
                />
              </span>
            </h1>
            <span className="beta">Beta</span>
          </div>
          <p>
            {t("Already have an account?")} &nbsp;
            <span>{t("Log In")}</span>
          </p>
          <Formik
            validateOnMount={true}
            enableReinitialize={true}
            validationSchema={SignupSchema}
            initialValues={initialValues}
            onSubmit={(values: any, { resetForm }) => {
              signUpUser(values)
                .then((res) => {
                  if (res.status == 200) {
                    signUpSuccess();
                    resetForm({});
                    onCancelFn();
                  }
                })
                .catch((res) => {
                  console.log(res);
                  resetForm({});
                  signUpError();
                });
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <div
                  className={
                    isDarkMode
                      ? "subscribe-label-wrapper dark"
                      : "subscribe-label-wrapper"
                  }
                >
                  <Field name="username" type="text" placeholder={t("Name")} />
                </div>
                <div
                  className={
                    isDarkMode
                      ? "subscribe-label-wrapper dark"
                      : "subscribe-label-wrapper"
                  }
                >
                  <Field name="email" type="email" placeholder={t("E-Mail")} />
                </div>
                <div
                  className={
                    isDarkMode
                      ? "subscribe-label-wrapper dark"
                      : "subscribe-label-wrapper"
                  }
                >
                  <Field
                    name="password"
                    type="password"
                    placeholder={t("Password")}
                  />
                </div>
                <div className="agree">
                  {t(
                    "By providing your email address, you agree to our Privacy Policy and Terms of Service."
                  )}
                </div>
                <div className="form-error-msg">
                  <p>
                    <ErrorMessage name="name" />
                  </p>
                  <p>
                    <ErrorMessage name="email" />
                  </p>
                  <p>
                    <ErrorMessage name="password" />
                  </p>
                  <p>
                    <ErrorMessage name="confirmPassword" />
                  </p>
                </div>
                <button className="subscribe-btn" type="submit">
                  {t("Sign Up")}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
};

export default SignUpModal;
