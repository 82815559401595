import "../../App.scss";
import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../components/Header";
import TopHeader from "../components/TopHeader";
import { useTheme } from "../context/ThemeContext";
import { SunOutlined, MoonOutlined } from "@ant-design/icons";

import "../../i18next";
import Item from "./Items";
import Post from "./Post";
import Footer from "../components/Footer";
import AllColumnPost from "./AllColumnPost";

const Main: React.FC = () => {
  const { isDarkMode, toggleTheme } = useTheme();

  return (
    <div className={isDarkMode ? "full-wrapper wrapper-dark" : "full-wrapper"}>
      <div className={isDarkMode ? "upper upper-dark" : "upper upper-light"}>
        <TopHeader />
        <Header />
      </div>
      <Routes>
        <Route path="/*" element={<Item />}></Route>
        <Route path="/breaking-news/*" element={<Post />}></Route>
        <Route path="/all-column-news" element={<AllColumnPost />}></Route>
      </Routes>
      <span
        className={isDarkMode ? "darkmode-switch dark" : "darkmode-switch"}
        onClick={toggleTheme}
      >
        {isDarkMode ? (
          <MoonOutlined style={{ fontSize: "2em", cursor: "pointer" }} />
        ) : (
          <SunOutlined style={{ fontSize: "2em", cursor: "pointer" }} />
        )}
      </span>
      <Footer />
    </div>
  );
};

export default Main;
