import "../../App.scss";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";

import { useTheme } from "../context/ThemeContext";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { Column } from "../utility/interface/common";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getColumn, getMoreColumn } from "../server/list";
import { chooseColumn } from "../features/selection/selectionSlice";
import { Skeleton } from "antd";

const Post = () => {
  const { t } = useTranslation();
  const { isDarkMode } = useTheme();
  const settingsState = useAppSelector((state: any) => state.settings);
  const selectionState = useAppSelector((state: any) => state.selection);
  const [moreColumnList, setMoreColumnList] = useState<any>([]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const getTitle = (item: Column) => {
    switch (settingsState.lang) {
      case "zh_tw_":
        return item.zh_tw_title;
      case "zh_cn_":
        return item.zh_cn_title;
      case "es_":
        return item.es_title;
      case "ko_":
        return item.ko_title;
      case "ja_":
        return item.ja_title;
      default:
        return item.eng_title;
    }
  };

  const getContent = (item: Column) => {
    switch (settingsState.lang) {
      case "zh_tw_":
        return item.zh_tw_content;
      case "zh_cn_":
        return item.zh_cn_content;
      case "es_":
        return item.es_content;
      case "ko_":
        return item.ko_content;
      case "ja_":
        return item.ja_content;
      default:
        return item.eng_content;
    }
  };

  const chooseColumnFn = (column: Column, title: string) => {
    dispatch(chooseColumn(column));
    navigate(`/breaking-news/${title}`);
  };

  useEffect(() => {
    getMoreColumn(settingsState.region)
      .then((res) => {
        const data = res.data.column_30;
        console.log(data);
        setMoreColumnList(data);
      })
      .catch((err) => console.log(err));
  }, [settingsState.region]);

  useEffect(() => {
    if (selectionState._id === "") {
      navigate("/");
    }
  }, []);

  return (
    <div className="wrapper">
      <div className="single-post">
        <h1 className={isDarkMode ? "post-media-body dark" : "post-media-body"}>
          {getTitle(selectionState)}
        </h1>
        <div className={isDarkMode ? "post-meta dark" : "post-meta"}>
          Published at {selectionState.publishedAt}
        </div>
        <div className="single-post-wrapper">
          <div className="post-detail">
            <div className="image-container">
              <img src={selectionState.image_url} alt="" />
            </div>
            <div className={isDarkMode ? "inner-post dark" : "inner-post"}>
              <ReactMarkdown>{getContent(selectionState)!}</ReactMarkdown>
            </div>

            <div className="legal">
              <p>{t("Legal1")}</p>
              <p>{t("Legal2")}</p>
              <p>{t("Legal3")}</p>
              <p>{t("Legal4")}</p>
            </div>
          </div>
          <div className={isDarkMode ? "more-from dark" : "more-from"}>
            <h1>{t("More from AxonNews")}</h1>
            {moreColumnList.length === 0 ? (
              <Skeleton active />
            ) : (
              <ul>
                {moreColumnList
                  .filter(
                    (post: Column) =>
                      post.eng_title !== selectionState.eng_title
                  )
                  .slice(15)
                  .map((item: Column) => {
                    return (
                      <li
                        key={item._id}
                        onClick={() => chooseColumnFn(item, item.title!)}
                      >
                        <img src={item.image_url} height={"100px"} />
                        <span>{getTitle(item)}</span>
                      </li>
                    );
                  })}
                <li onClick={() => navigate("/all-column-news")}>
                  {t("Read More...")}
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Post;
