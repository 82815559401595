import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import esCommon from "./assets/locales/es/common.json";
import koCommon from "./assets/locales/ko/common.json";
import jaCommon from "./assets/locales/ja/common.json";
import zhCNCommon from "./assets/locales/zh_cn/common.json";
import zhTWCommon from "./assets/locales/zh_tw/common.json";
import enUSCommon from "./assets/locales/en/common.json";
import { useAppSelector } from "./app/store/hooks";

i18n.use(initReactI18next).init({
  ns: ["common"],
  resources: {
    ja_: {
      common: jaCommon,
    },
    es_: {
      common: esCommon,
    },
    ko_: {
      common: koCommon,
    },
    zh_tw_: {
      common: zhTWCommon,
    },
    zh_cn_: {
      common: zhCNCommon,
    },
    en_: {
      common: enUSCommon,
    },
  },
  lng: "en_",
});
